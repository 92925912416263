import { FaLinkedin, FaFacebook, FaWhatsapp } from "react-icons/fa";

const socialLinks = [
  {
    name: 'Facebook',
    icon: <FaFacebook />,
    href: 'https://facebook.com',
    color: '#333333'
  },
  {
    name: 'LinkedIn',
    icon: <FaLinkedin />,
    href: 'https://linkedin.com',
    color: '#0A66C2'
  },
  {
    name: 'WhatsApp',
    icon: <FaWhatsapp />,
    href: 'https://wa.me',
    color: '#E4405F'
  }
];

export default function SocialNetworks() {
  return (
    <div className="d-flex align-items-center gap-3">
      {socialLinks.map((social) => {
        const Icon = social.icon;
        return (
          <a
            key={social.name}
            href={social.href}
            target="_blank"
            rel="noopener noreferrer"
            className="text-secondary transition-hover"
            style={{
              '--hover-color': social.color
            }}
            aria-label={social.name}
          >
            <Icon className="social-icon" />
          </a>
        );
      })}
    </div>
  );
}