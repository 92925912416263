import {FaUserTie, FaWhatsapp} from "react-icons/fa";
import React from "react";


function Index() {



    return (
        <>

            <a href="https://wa.me/22959112138" target="_blank" className="whatsapp-icon">
                <FaWhatsapp size={35} color="#FFFFFF" />

            </a>

        </>
    );
}

export default Index;