import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Tab, Tabs } from 'react-bootstrap';

const Sidebar = ({ openClass }) => {
    const [isActive, setIsActive] = useState({
        status: false,
        key: "",
    });

    const handleToggle = (key) => {
        if (isActive.key === key) {
            setIsActive({
                status: false,
            });
        } else {
            setIsActive({
                status: true,
                key,
            });
        }
    };

    return (
        <div className={`mobile-header-active mobile-header-wrapper-style perfect-scrollbar ${openClass}`}>
            <div className="mobile-header-wrapper-inner">
                <div className="mobile-header-content-area">
                    <div className="mobile-logo">
                        <Link className="d-flex" to="/">
                            <img alt="Becotrac" src="assets/imgs/template/logo-becotrac-long.png" />
                        </Link>
                    </div>
                    <div className="perfect-scroll">
                        <div className="mobile-menu-wrap mobile-header-border">
                            <Tabs
                                defaultActiveKey="menu"
                                id="fill-tab-example"
                                className="nav nav-tabs nav-tabs-mobile mt-25"
                                fill
                            >
                                <Tab eventKey="menu" title="Menu">
                                    <div className="tab-pane">
                                        <nav className="mt-15">
                                            <ul className="mobile-menu font-heading">
                                                <li>
                                                    <Link className="active" to="/">Accueil</Link>
                                                </li>
                                                <li>
                                                    <Link to="/about">Qui sommes-nous ?</Link>
                                                </li>
                                                <li className="mt-4">
                                                    <Link 
                                                        className="btn btn-brand-1 w-full text-center text-white" 
                                                        to="mailto:contact@becotrac.com"
                                                    >
                                                        Prendre contact
                                                    </Link>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;