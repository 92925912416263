import { Link } from 'react-router-dom';
import React from 'react';
import { FaFacebook, FaLinkedinIn, FaWhatsapp } from 'react-icons/fa';
import SocialNetworks from '../SocialNetworks';
import { useTranslation } from 'react-i18next';

const SocialLinks = () => {
    

    const socialLinks = [
    {
      icon: <FaFacebook size={24} />,
      href: 'https://www.facebook.com/becotrac/',
      label: 'Facebook',
      hoverColor: 'hover:#28436F'
    },
    {
      icon: <FaLinkedinIn size={24} />,
      href: 'https://www.linkedin.com/company/becotrac-assurances/',
      label: 'LinkedIn',
      hoverColor: 'hover:#28436F'
    },
    {
      icon: <FaWhatsapp size={24} />,
      href: 'https://wa.me/22959112138',
      label: 'WhatsApp',
      hoverColor: 'hover:#28436F'
    }
  ];

  return (
    <div className="mt-4 d-flex gap-2">
      {socialLinks.map((link, index) => (
        <Link
          key={index}
          to={link.href}
          target="_blank"
          rel="noopener noreferrer"
          className={`text-gray-600 transition-colors duration-200`}
          aria-label={`Visitez notre ${link.label}`}
        >
          {link.icon}
        </Link>
      ))}
    </div>
  );
};

const Footer = () => {

    const { t, i18n } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    return (
        <>
            <footer className="footer">
                <div className="footer-1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-12 text-center">
                                <div className="mb-10"><img src={t('logo')} width={"80%"} alt="Becotrac" /></div>
                                
                                {/*<div className="font-md mb-20 color-grey-400"><strong className="font-md-bold">Horaires :</strong> 8:00 - 17:00, Mon - Sat</div>
                                <h6 className="color-brand-1">Nos réseaux sociaux</h6>*/}
                                
                            </div>
                            <div className="col-lg-4 col-6 mb-30">
                                <h5 className="mb-10 color-brand-1">{t('footer.contact')}</h5>
                                <ul className="menu-footer">
                                    <li><p className="font-md mb-20 color-grey-400">{t('footer.address')}<br className="d-none d-lg-block" /></p></li>
                                    <li><Link className="font-md mb-20 color-grey-400" to="mailto:contact@becotrac.com">contact@becotrac.com</Link></li>
                                    <li><Link className="font-md mb-20 color-grey-400" to="https://wa.me/22959112138">(+229) 59 11 21 38</Link></li>
                                    <li><Link className="font-md mb-20 color-grey-400" to="tel:+2290120222581">(+229) 01 20 22 25 81</Link></li>
                                </ul>
                            </div>
                            <div className="col-lg-4 col-6 mb-30">
                                <h5 className="mb-10 color-brand-1">{t('footer.social_networks')}</h5>
                                {/*<ul className="menu-footer">
                                    <li><Link to="#">Project managemen</Link></li>
                                    <li><Link to="#">Solutions</Link></li>
                                    <li><Link to="#">Customers</Link></li>
                                    <li><Link to="#">News &amp; Events</Link></li>
                                    <li><Link to="#">Careers</Link></li>
                                    <li><Link to="#">Support</Link></li>
                                </ul>*/}
                                <SocialLinks />
                            </div>
                            {/*<div className="col-lg-3 width-16 mb-30">
                                <h5 className="mb-10 color-brand-1">We offer</h5>
                                <ul className="menu-footer">
                                    <li><Link to="#">Project  software</Link></li>
                                    <li><Link to="#">Resource  software</Link></li>
                                    <li><Link to="#">Workflow automation</Link></li>
                                    <li><Link to="#">Gantt chart makers</Link></li>
                                    <li><Link to="#">Project dashboards</Link></li>
                                    <li><Link to="#">Task  software</Link></li>
                                </ul>
                            </div>
                            <div className="col-lg-3 width-23">
                                <h5 className="mb-10 color-brand-1">App &amp; Payment</h5>
                                <div>
                                    <p className="font-sm color-grey-400">Download our Apps and get extra 15% Discount on your first Order…!</p>
                                    <div className="mt-20"><Link className="mr-10" to="#"><img src="assets/imgs/template/appstore.png" alt="iori" /></Link><Link to="#"><img src="assets/imgs/template/google-play.png" alt="iori" /></Link></div>
                                    <p className="font-sm color-grey-400 mt-20 mb-10">Secured Payment Gateways</p><img src="assets/imgs/template/payment-method.png" alt="iori" />
                                </div>
                            </div>*/}
                        </div>
                    </div>
                </div>
                <div className="footer-2">
                    <div className="container">
                        <div className="footer-bottom">
                            <div className="row">
                                <div className="col-lg-6 col-md-12 text-center text-lg-start">
                                    {/*<ul className="menu-bottom">
                                        <li><Link className="font-sm color-grey-300" to="/term-conditions">Privacy policy</Link></li>
                                        <li><Link className="font-sm color-grey-300" to="/term-conditions">Cookies</Link></li>
                                        <li><Link className="font-sm color-grey-300" to="/term-conditions">Terms of service</Link></li>
                                    </ul>*/}
                                </div>
                                <div className="col-lg-6 col-md-12 text-center text-lg-end"><span className="color-grey-300 font-md">{t('footer.copyright')}</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>


        </>
    );
};

export default Footer;