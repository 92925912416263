import { Link } from 'react-router-dom';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Globe, ChevronDown } from 'lucide-react';

const languages = {
  en: { name: 'English', flag: '🇬🇧' },
  es: { name: 'Español', flag: '🇪🇸' },
  fr: { name: 'Français', flag: '🇫🇷' },
};

const Header = ({ handleOpen, handleRemove, openClass, addClass }) => {

    const [scroll, setScroll] = useState(0)
    const [isSearchToggled, setSearchToggled] = useState(false);
    const [isLanguageToggled, setLanguageToggled] = useState(false);
    const toggleSearchTrueFalse = () => setSearchToggled(!isSearchToggled);
    const toggleLanguageTrueFalse = () => setLanguageToggled(!isLanguageToggled);

    const { t, i18n } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setIsOpen(false);
    };

    const currentLanguage = languages[i18n.language] || languages.en;

    useEffect(() => {
        const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);
    
    useEffect(() => {
        document.addEventListener("scroll", () => {
            const scrollCheck = window.scrollY > 100
            if (scrollCheck !== scroll) {
                setScroll(scrollCheck)
            }
        })
    })
    return (
        <>
            <header className={`${scroll ? "header sticky-bar stick" : "header sticky-bar"} ${addClass}`}>
                <div className="container">
                    <div className="main-header">
                        <div className="header-left">
                            <div className="header-logo"><Link className="d-flex" to="/"><img alt="Becotrac assurance" src={t('logo')} /></Link></div>
                            <div className="header-nav">
                                <nav className="nav-main-menu d-none d-xl-block">
                                    
                                    <ul className="main-menu">
                                        <li><Link className="active" to="/">{t('header.home')}</Link>
                                        </li>
                                        <li><Link to="/about">{t('header.about')}</Link>
                                        </li>
                                        <li><Link to="becotrac-sante">{t('health.menu')}</Link>
                                        </li>
                                        {/*<li className="has-children"><Link to="#">Career</Link>
                                            <ul className="sub-menu">
                                                <li><Link to="/career">Jobs Listing</Link></li>
                                                <li><Link to="/job-detail">Job Details</Link></li>
                                            </ul>
                                        </li>*/}
                                        {/*<li><Link to="/blog">Blog</Link>
                                            <ul className="sub-menu">
                                                <li><Link to="/blog">Blog Listing 1</Link></li>
                                                <li><Link to="/blog-2">Blog Listing 2</Link></li>
                                                <li><Link to="/blog-detail">Blog Details</Link></li>
                                            </ul>
                                        </li>*/}
                                        {/*<li className="has-children"><Link to="#">Shop</Link>
                                            <ul className="sub-menu">
                                                <li><Link to="/shop-grid">Products Listing 1</Link></li>
                                                <li><Link to="/shop-list">Products Listing 2</Link></li>
                                                <li><Link to="/product-detail">Product Details</Link></li>
                                            </ul>
                                        </li>
                                        <li className="has-children"><Link to="#">Pages</Link>
                                            <ul className="sub-menu">
                                                <li><Link to="/register">Register</Link></li>
                                                <li><Link to="/login">Login</Link></li>
                                                <li><Link to="/coming-soon">Coming soon</Link></li>
                                                <li><Link to="/404">Error 404</Link></li>
                                            </ul>
                                        </li>
                                        <li><Link to="/contact">Contact</Link></li>*/}
                                    </ul>
                                </nav>
                                <div className={`burger-icon burger-icon-white ${openClass && "burger-close"}`}
                                    onClick={() => { handleOpen(); handleRemove() }}>
                                    <span className="burger-icon-top" />
                                    <span className="burger-icon-mid" />
                                    <span className="burger-icon-bottom" />
                                </div>
                            </div>
                        </div>
                        <div className="header-right"  style={{
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    gap: '25px',
    flexDirection: 'row',
    width: '30%'
}}>
                                {/*<div className="d-inline-block box-search-top">
                                    <div className={isSearchToggled ? "form-search-top d-block" : " form-search-top d-none"}>
                                        <form action="#">
                                            <input className="input-search" type="text" placeholder="Search..." />
                                            <button className="btn btn-search">
                                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                                </svg>
                                            </button>
                                        </form>
                                    </div>
                                    <span className="font-lg icon-list search-post" onClick={toggleSearchTrueFalse}>
                                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                        </svg></span>
                                </div>*/}
                                
                                <div className="d-none d-sm-inline-block"><Link className="btn btn-brand-1 hover-up" to="mailto:contact@becotrac.com">{t('risk_management_section.contact_button')}</Link></div>
                                <div className="dropdown" ref={dropdownRef}>
                                    <button
                                    className="btn btn-light language-btn"
                                    onClick={() => setIsOpen(!isOpen)}
                                    aria-expanded={isOpen}
                                    >
                                    <span>{currentLanguage.flag}</span>
                                    <span>{` `+currentLanguage.name+` `}</span>
                                    <ChevronDown size={16} />
                                    </button>
                                    <ul className={`dropdown-menu ${isOpen ? 'show' : ''} text-dark`}>
                                    {Object.entries(languages).map(([code, { name, flag }]) => (
                                        <li key={code}>
                                        <button
                                            className="dropdown-item"
                                            onClick={() => changeLanguage(code)}
                                        >
                                            <span>{flag}</span>
                                            <span>{` `+name}</span>
                                        </button>
                                        </li>
                                    ))}
                                    </ul>
                                </div>
                            </div>
                            
                    </div>
                </div>
            </header>


        </>
    );
};

export default Header;