import React, {lazy, Suspense} from 'react';
import { Route, Routes } from 'react-router-dom';
import Loading from './components/Loading';
import Layout from './components/Layout';
import { Analytics } from '@vercel/analytics/react';
import "swiper/css";
import "swiper/css/navigation";
import './assets/css/style.css';
import { useTranslation } from 'react-i18next';

const Home = lazy(() => import('./pages/Home'));
const AboutPage = lazy(() => import('./pages/About'));
const HealthInsurancePage = lazy(() => import('./pages/HealthInsurance'));
const ErrorPage = lazy(() => import('./pages/Error'));


function App() {

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  
  return (
    <>
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="becotrac-sante" element={<HealthInsurancePage />} />
          <Route path="becotrac-sante/" element={<HealthInsurancePage />} />
          <Route path={t('health.link')} element={<HealthInsurancePage />} />
          <Route path="health-insurance" element={<HealthInsurancePage />} />
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Suspense>
    <Analytics />
    </>
  );
}

export default App;